@import 'bootstrap/dist/css/bootstrap.min.css';
@import url( 'https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');


* {
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
}

body,
html {
    height: 100vh;
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



.App {
    font-family: "Roboto"
}