.navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #438b8e;
    width: 100%;
}

nav::after {
    content: "";
    position: absolute;
    left: 0;
    top: 99%;
    background-image: url('../../assets/waveBottom.svg');
    background-repeat: repeat-x;
    width: 100vw;
    height: 50px;
    
}

.logo {
    width: 78px;
    height: 100px;
}

.bag {
    color: #fff;
}
